<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info"
                          @click="updateStatusFromExchange">
                    بروزرسانی وضعیت
                    ({{ lastUpdate ? $G2J(lastUpdate) : 'به روز نیست' }})
                </b-button>
            </div>
            <div>
                <CryptoCurrencyTable :items="items"/>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BOverlay,
        BButton,
        // BLink,
        // BMedia,
    } from 'bootstrap-vue'
    import CryptoCurrencyTable from "@/views/CryptoCurrency/CryptoCurrencyTable";
    import vSelect from 'vue-select';

    export default {
        name: "CryptoCurrency",
        components: {
            CryptoCurrencyTable,
            BCard,
            BOverlay,
            BButton,
            BPagination,
            // BLink,
            // BMedia,
            vSelect
        },
        data: () => ({
            dir: false,
            loading: false,
            coins: [],
            items: [],
            items1: [],
            data: {},
            edit: false,
            type: false,
            perPageOptions: [5, 10, 20, 50, 100],
            currentPage: 1,
            perPage: 10,
            rows: 10,
            lastUpdate : false
        }),
        methods: {
            async getData(page, perPage) {
                this.state.loading = true
                const queryParams = {
                    size: perPage,
                    page: page,
                    coinType: 'crypto',
                    ...this.$route.query
                }
                const res = await this.$axios.get('/coins', {
                    params: queryParams
                })
                this.items = res.data.data
                this.currentPage = res.data.meta.current_page
                this.rows = res.data.meta.total
            },
            async updateStatusFromExchange() {
                await this.$axios.post('/networks')

               await this.getData(this.currentPage, this.perPage);
               await this.getLastUpdate()
            },
            async getLastUpdate() {
                try {
                    const res = await this.$axios.get('/configs/SCHEDULE/ALL_COINS')
                    this.lastUpdate = res.data.data.value
                } catch (e) {
                    e
                }
            }
        },
        async created() {
            await this.getData(1, this.perPage)
            await this.getLastUpdate()
        }
    }
</script>

<style scoped>

</style>
